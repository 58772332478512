<template>
  <v-row
    id="monthSelector"
    align="center"
    :justify="$vuetify.breakpoint.xsOnly ? 'center' : null"
  >
    <v-btn-toggle v-model="btn" mandatory class="pl-0 pl-md-3">
      <v-menu
        v-model="menu.day"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-on="on"
            value="day"
            active-class="selected"
            outlined
            height="30"
            >{{ $t("day") }}</v-btn
          >
        </template>
        <v-date-picker
          first-day-of-week="1"
          no-title
          v-model="day"
          @input="menu.day = false"
          color="primary"
        ></v-date-picker>
      </v-menu>

      <v-menu
        v-model="menu.week"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-on="on"
            value="week"
            active-class="selected"
            outlined
            height="30"
            >{{ $t("week") }}</v-btn
          >
        </template>
        <v-date-picker
          first-day-of-week="1"
          no-title
          v-model="week"
          @input="menu.week = false"
          color="primary"
          range
          class="weekSelector"
        ></v-date-picker>
      </v-menu>
      <v-menu
        v-model="menu.month"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-on="on"
            value="month"
            active-class="selected"
            outlined
            height="30"
          >
            {{ $t("month") }}
          </v-btn>
        </template>
        <v-date-picker
          first-day-of-week="1"
          no-title
          v-model="month"
          @input="menu.month = false"
          type="month"
          color="primary"
        ></v-date-picker>
      </v-menu>
    </v-btn-toggle>
    <v-col
      cols="12"
      sm="6"
      md="4"
      lg="4"
      :style="
        $vuetify.breakpoint.xsOnly
          ? 'display:flex; justify-content: end; align-items: right'
          : ''
      "
    >
      <v-row
        :style="
          $vuetify.breakpoint.xsOnly
            ? 'display:flex; justify-content: center; align-items: center'
            : ''
        "
      >
        <v-col cols="4" style="min-width: 140px; max-width: 140px" class="py-0">
          <v-menu
            v-model="menu.first_day"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="daymenu"
                outlined
                dense
                hide-details
                :value="$d(new Date(from), 'date2digits')"
                readonly
                v-bind="attrs"
                v-bind:class="{ 'error--text': from > to }"
                v-on="on"
              >
                <template v-slot:prepend-inner
                  ><v-icon class="mt-1" small>$appointments</v-icon></template
                ></v-text-field
              >
            </template>
            <v-date-picker
              first-day-of-week="1"
              v-model="from"
              color="primary"
              no-title
              @input="menu.first_day = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <span
          v-if="$vuetify.breakpoint.smAndDown"
          style="font-weight: bolder; font-size: 20px"
          >-
        </span>
        <span v-else class="mt-2">- </span>
        <v-col cols="4" class="py-0" style="min-width: 140px; max-width: 140px">
          <v-menu
            v-model="menu.last_day"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="daymenu"
                outlined
                dense
                hide-details
                :value="$d(new Date(to), 'date2digits')"
                readonly
                v-bind="attrs"
                v-on="on"
                v-bind:class="{ 'error--text': from > to }"
              >
                <template v-slot:prepend-inner
                  ><v-icon class="mt-1" small>$appointments</v-icon>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              first-day-of-week="1"
              v-model="to"
              color="primary"
              :min="from"
              no-title
              @input="menu.last_day = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

import * as moment from "moment";
export default {
  name: "MonthSelector",
  data() {
    return {
      menu: {
        month: false,
        day: false,
        week: false,
      },
    };
  },
  mounted() {
    if (!this.day) this.day = moment().format("YYYY-MM-DD");
    else this.day = this.day;
  },
  computed: {
    ...mapState("reports", ["filters", "filter_type"]),
    btn: {
      get() {
        return this.filter_type;
      },
      set(value) {
        this.$store.commit("reports/SET_FILTER_TYPE", value);
      },
    },
    from: {
      get() {
        return this.filters.from;
      },
      set(value) {
        this.$store.commit("reports/SET_FROM", value);
        this.$store.commit("expenses/SET_EXPENSES_FROM", value);
      },
    },
    to: {
      get() {
        return this.filters.to;
      },
      set(value) {
        this.$store.commit("reports/SET_TO", value);
        this.$store.commit("expenses/SET_EXPENSES_TO", value);
      },
    },
    month: {
      get() {
        let split = this.filters.from.split("-");
        return `${split[0]}-${split[1]}`;
      },
      set(value) {
        //console.log("%c%s", "color: #00e600", value);
        console.log(value);
        value = value.split("-");

        let from = new Date(value[0], value[1] - 1, 1);
        this.$store.commit(
          "reports/SET_FROM",
          `${from.getFullYear()}-${
            from.getMonth() + 1 >= 10
              ? +from.getMonth() + 1
              : "0" + (+from.getMonth() + 1)
          }-${from.getDate() >= 10 ? from.getDate() : "0" + from.getDate()}`
        );
        this.$store.commit(
          "expenses/SET_EXPENSES_FROM",
          `${from.getFullYear()}-${
            from.getMonth() + 1 >= 10
              ? +from.getMonth() + 1
              : "0" + (+from.getMonth() + 1)
          }-${from.getDate() >= 10 ? from.getDate() : "0" + from.getDate()}`
        );
        let to = new Date(value[0], value[1], 0);
        //console.log(from);
        //console.log(to);
        this.$store.commit(
          "reports/SET_TO",
          `${to.getFullYear()}-${
            to.getMonth() + 1 >= 10
              ? +to.getMonth() + 1
              : "0" + (+to.getMonth() + 1)
          }-${to.getDate() >= 10 ? to.getDate() : "0" + to.getDate()}`
        );
        this.$store.commit(
          "expenses/SET_EXPENSES_TO",
          `${to.getFullYear()}-${
            to.getMonth() + 1 >= 10
              ? +to.getMonth() + 1
              : "0" + (+to.getMonth() + 1)
          }-${to.getDate() >= 10 ? to.getDate() : "0" + to.getDate()}`
        );
      },
    },
    week: {
      get() {
        //return [];
        return [this.filters.from, this.filters.to];
      },
      set(value) {
        value = value[0];
        value = new Date(value);
        let d = value.getDate() + 1;
        let di = value.getDay() === 0 ? 7 : value.getDay();
        value.setDate(d - di);
        this.$store.commit(
          "reports/SET_FROM",
          `${value.getFullYear()}-${
            value.getMonth() + 1 >= 10
              ? value.getMonth() + 1
              : "0" + (value.getMonth() + 1)
          }-${value.getDate() >= 10 ? value.getDate() : "0" + value.getDate()}`
        );
        this.$store.commit(
          "expenses/SET_EXPENSES_FROM",
          `${value.getFullYear()}-${
            value.getMonth() + 1 >= 10
              ? value.getMonth() + 1
              : "0" + (value.getMonth() + 1)
          }-${value.getDate() >= 10 ? value.getDate() : "0" + value.getDate()}`
        );

        value.setDate(value.getDate() + 6);
        this.$store.commit(
          "reports/SET_TO",
          `${value.getFullYear()}-${
            value.getMonth() + 1 >= 10
              ? value.getMonth() + 1
              : "0" + (value.getMonth() + 1)
          }-${value.getDate() >= 10 ? value.getDate() : "0" + value.getDate()}`
        );

        this.$store.commit(
          "expenses/SET_EXPENSES_TO",
          `${value.getFullYear()}-${
            value.getMonth() + 1 >= 10
              ? value.getMonth() + 1
              : "0" + (value.getMonth() + 1)
          }-${value.getDate() >= 10 ? value.getDate() : "0" + value.getDate()}`
        );
      },
    },
    day: {
      get() {
        return this.filters.from;
      },
      set(value) {
        this.$store.commit("reports/SET_FROM", value);
        this.$store.commit("reports/SET_TO", value);
        this.$store.commit("expenses/SET_EXPENSES_FROM", value);
        this.$store.commit("expenses/SET_EXPENSES_TO", value);
      },
    },
  },
};
</script>

<style lang="sass">
.v-application--is-ltr .v-btn-toggle > .v-btn.v-btn:not(:first-child)
  border-left-width: 0.5px

.v-btn-toggle .v-btn
  background-color: var(--v-gris2-base)
#monthSelector
  .selected.selected.selected
    color: black !important
    border-color: var(--v-primary-base) !important
    background-color: var(--v-primary-base) !important
  .daymenu
    .svg-icon
      color: var(--v-primary-base)

.weekSelector
  .theme--dark.v-btn:hover::before
    opacity: 0
  tr
    &:hover
      td
        background-color: var(--v-primary-base)
        border-radius: 0
        border: 1px solid var(--v-primary-base)
        &:first-child
          border-top-left-radius: 50%
          border-bottom-left-radius: 50%
        &:last-child
          border-top-right-radius: 50%
          border-bottom-right-radius: 50%
        .v-btn__content
          color: black !important

    td
      border-radius: 50px

      &:first-child
        border-left: thin var(--v-primary-base) solid

      &:last-child
        border-right: thin var(--v-primary-base) solid
</style>
